<template>
    <div id="project-photos">
        <swiper
        :space-between="30"
        :navigation="{ clickable: true }"
        :loop="true"
        :speed="600"
        :slides-per-view="1"
        @swiper="onSwiper">

            <swiper-slide v-for="(slide, index) in slides" :key="index" :style="'background-image: url(' + slide.image_large_computed + ')'">
                <!-- <div></div> -->
            </swiper-slide>
            
        </swiper>
    </div>
</template>

<script>
    import SwiperCore, {Navigation, Lazy} from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper.scss';
    // import 'swiper/components/zoom/zoom.scss'; // This does not load when built for production

    SwiperCore.use([Navigation, Lazy]);

    export default {
        name: 'project-photos',
        props: {
            slides: Object,
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        methods: {
            onSwiper(swiper)
            {
                // Must call "update" with a delay in order for swiper to initialize on page load.
                // Otherwise, does not initialize until window is resized.

                setTimeout(() => {
                    swiper.update();
                }, 100)
                
            }
        },
    }
</script>

<style lang="scss">

    #project-photos {


        .swiper-container {
            width: 100%;
            height: 45vw;
            /*height: 700px;*/
            /*min-height: 500px;*/
            /*max-height: 100vh;*/
            /*background: #eee;*/

            .swiper-wrapper {

                .swiper-slide {

                    /*& > div {*/
                        height: 100%;
                        max-height: 80vh;

                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                    /*}*/
                }
            }

            @media ( max-width: 1200px ) {
                height: 75vw;
                max-height: 80vh;
            }
        }


        /* Custom Swiper Navigation CSS since the one from the package doesnt load in production build?! */

        .swiper-container {
            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                height: 100%;
                max-height: 80vh;
                width: 60px;
                cursor: pointer;
            }

            .swiper-button-prev {
                left: 0; top: 0;
                z-index: 100;
                background: rgba(0,0,0,.3) url(../assets/icons/icon-arrow-back-white.svg) no-repeat center center;
                background-size: 60%;
            }

            .swiper-button-next {
                right: 0; top: 0;
                z-index: 101;
                background: rgba(0,0,0,.3) url(../assets/icons/icon-arrow-next-white.svg) no-repeat center center;
                background-size: 60%;
            }
        }
    }

</style>
