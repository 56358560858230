

<script>
import NavBarLarge from './components/NavBarLarge.vue'
import NavBarSmall from './components/NavBarSmall.vue'
import HomeBanner from './components/HomeBanner.vue';
import ProjectPhotos from './components/ProjectPhotos.vue';
import TeamList from './components/TeamList.vue';
import ContactUsEmail from './components/ContactUsEmail.vue';
import ContactForm from './components/ContactForm.vue';
import Modal from './components/Modal.vue';
import Socials from './components/Socials.vue'

export default {
    name: 'App',
    data: () => ({
        path: String,
        showScheduleTourModal: false,
        selectedPropertyId: 0,
    }),
    components: {
        NavBarLarge,
        NavBarSmall,
        HomeBanner,
        ProjectPhotos,
        TeamList,
        ContactUsEmail,
        ContactForm,
        Modal,
        Socials,
    },
    mounted: function() {
        let url = window.location.href;
        url = url.split('//')[1];
        url = url.split('/');
        this.path = url;
    },
    methods: {
        
    }
}
</script>

<style>
#app {
    
}
</style>
