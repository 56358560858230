<template>
    <div id="contact-form">

        <Toast :base-z-index="3000" position="bottom-right" />

        <Progress :visible="loading" />

        <div v-if="success">
            <Message severity="success" :closable="false">Thanks for contacting Empire Residential</Message>
        </div>

        <div v-if="!success" class="form">

        <div class="g-span-1">
            <span class="p-float-label">
                <InputText 
                type="text" 
                id="firstName" 
                name="firstName"
                v-model="formData.firstName" 
                class="p-inputtext-lg"
                :class="{ 'p-invalid': errors['firstName'] }" />
                <label for="firstName" :class="{ 'p-invalid': errors['firstName'] }">First Name</label>
                <small v-if="errors['firstName']" class="p-invalid">{{ errors['firstName'] }}</small>
            </span>
        </div>

        <div class="g-span-1">
            <span class="p-float-label">
                <InputText 
                type="text" 
                id="lastName" 
                name="lastName"
                v-model="formData.lastName" 
                class="p-inputtext-lg"
                :class="{ 'p-invalid': errors['lastName'] }" />
                <label for="lastName" :class="{ 'p-invalid': errors['lastName'] }">Last Name</label>
                <small v-if="errors['lastName']" class="p-invalid">{{ errors['lastName'] }}</small>
            </span>
        </div>

        <div class="g-span-1">
            <span class="p-float-label">
                <InputText 
                type="text" 
                id="email" 
                name="email"
                v-model="formData.email" 
                class="p-inputtext-lg"
                :class="{ 'p-invalid': errors['email'] }" />
                <label for="lastName" :class="{ 'p-invalid': errors['email'] }">Your Email</label>
                <small v-if="errors['email']" class="p-invalid">{{ errors['email'] }}</small>
            </span>
        </div>

        <div class="g-span-1">
            <span class="p-float-label">
                <InputText type="text" id="phone" name="phone" v-model="formData.phone" class="p-inputtext-lg" />
                <label for="phone" :class="{ 'p-invalid': errors['phone'] }">Phone</label>
            </span>
        </div>

        <div class="g-span-2">
            <span class="p-float-label">
                <Textarea v-model="formData.message" :autoResize="true" rows="5" cols="100" />
                <label for="phone" :class="{ 'p-invalid': errors['message'] }">Message</label>
            </span>
        </div>

        <div class="g-span-1"></div>

        <div class="g-span-1" right>
            <Button label="Submit" @click="submit($event)" :disabled="loading" />
        </div>

        </div><!-- #success -->

    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import Button from 'primevue/button';
    import Toast from 'primevue/toast';
    import Progress from './Progress.vue';
    import Message from 'primevue/message';
    // import { validationMixin } from 'vuelidate'
    // import { required, maxLength, email } from 'vuelidate/lib/validators';

    export default {
        name: 'contact-us-email',
        components: {
            InputText,
            Textarea,
            Button,
            Toast,
            Progress,
            Message,
        },
        props: {
            contactId: Number,
            contactType: String, // team, contact-us, schedule-tour
            projectId: Number,
        },
        data: () => ({
            loading: false,
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
            },
            errors: [],
            success: false,
        }),
        methods: {
            submit () {
                this.loading = true;

                if ( !this.validate() ) {
                    this.$toast.add({severity:'error', summary: 'Form Error', detail:'Please check your form details.', life: 3000});
                    this.loading = false;
                } else {

                    window.axios.post('api/contact-form', this.formData)
                    .then(() => {
                        this.$toast.add({severity:'success', summary: 'Message Sent', detail:'Thanks for contacting us.', life: 3000});
                        this.loading = false;
                        this.success = true;
                    });
                }

            },

            validate() {
                this.errors = [];

                for ( let field in this.formData )
                {
                    switch ( field )
                    {
                        case 'firstName':
                        case 'lastName':
                        case 'email':
                        this.validateRequired(field, this.formData[field]);
                    }

                    if ( field === 'email' ) {
                        if ( !this.validateEmail(this.formData.email) ) {
                            this.errors[field] = 'Please check your email address';
                        }
                    }
                }

                return Object.keys(this.errors).length === 0;
            },

            validateRequired(n, v)
            {
                if ( !v || v === '' )
                {
                    this.errors[n] = 'This field is required';
                }
            },

            validateEmail (email) {
                let re = /^\S+@\S+$/;
                return re.test(email);
            }
        },

        mounted () {
            this.formData.contactId = this.contactId;
            this.formData.contactType = this.contactType;
            this.formData.projectId = this.projectId;
        }
    }
</script>

<style lang="scss">
    #contact-form > div.form {
        display: grid;
        grid-gap: 1em;
        grid-row-gap: 3em;
        grid-template-columns: 1fr 1fr;
        padding: 3em 1em 1em 1em;

        .g-span-1 {
            grid-column: auto / span 1;
        }

        .g-span-2 {
            grid-column: auto / span 2;
        }

        .p-inputtext {
            width: 100%;
        }

        span.p-float-label {
            label.p-invalid {
                /*top: 35%;*/
            }
        }

        @media ( max-width: 600px ) {
            & {
                grid-template-columns: 1fr;
                grid-row-gap: 2em;
            }

            .g-span-1,
            .g-span-2 {
                grid-column: auto;
            }
        }
    }
</style>