<template>
    <div id="team-list-wrapper">

        <div id="team-list">
            <div 
            v-for="t in team" :key="t.id"
            @mouseenter="activate(t.id)" 
            @touchstart="activate(t.id)" 
            @touchend="activate(t.id)" 
            class="team-item"
            :class="{ active: t.id === activeId }"
            @click="selectItem(t)">
                <i :style="'background-image: url('+t.image_computed+')'"></i>
                <h2>
                    {{ t.first_name + ' ' + t.last_name }}
                    <span v-if="t.title">{{ t.title }}</span>
                    <div class="button align-y-cen">
                        <b btn class="large">About {{ t.first_name }}</b>
                    </div>
                </h2>
            </div>
        </div>

        <div class="team-photo team-item"
        @mouseenter="activate(0)" 
        @touchstart="activate(0)" 
        @touchend="activate(0)"
        @click="selectItem({id: 0, first_name: 'Maintenance', last_name: ''}); email(); showModal = false"
        :class="{ active: 0 === activeId }">
            <i :style="'background-image: url(images/team-onsite-team.jpg)'" class="__colored"></i>
            <h2>
                <b>Maintenance Team</b>
                <span>Onsite</span>
                <div class="button" vcen>
                    <a btn class="large">Contact Maintenance</a>
                </div>
            </h2>
            
        </div>

        <Modal v-model:visible="showModal" width="500px" :title="'About ' + selection.first_name">
            <!-- <p><img :src="selection.image_computed" /></p> -->
            <h3>{{ selection.first_name + ' ' + selection.last_name }}</h3>
            <p><b v-if="selection.title">{{ selection.title }}</b></p>
            <p v-if="selection.since">Employee since: {{ selection.since }}</p>
            <p v-if="selection.bio" style="white-space: pre-wrap;">{{ selection.bio }}</p>
            <p v-if="selection.email">
                <a btn class="large" @click="email(); showModal = false">Email {{ selection.first_name }}</a>
            </p>
        </Modal>

        <Modal v-model:visible="showModalEmail" width="500px" :title="'Email ' + selection.first_name + ' ' + selection.last_name">
            <!-- <ContactForm :email="selection.email"></ContactForm> -->
            <ContactForm :contact-id="selection.id" contact-type="team"></ContactForm>
        </Modal>

    </div>
</template>

<script>
    import Modal from './Modal.vue';
    import ContactForm from './ContactForm.vue';

    export default {
        name: 'team-list',
        components: {
            Modal,
            ContactForm,
        },
        props: {
            team: Array,
        },
        data: () => ({
            activeId: null,
            showModal: false,
            showModalEmail: false,
            selection: {},
        }),
        methods: {
            activate(id)
            {
                this.activeId = id;
            },
            selectItem (t) {
                this.selection = t;
                this.showModal = true;
            },
            email () {
                this.showModalEmail = true;
            }
        },
    }
</script>

<style lang="scss">

    #team-list-wrapper {
        .modal {
            /*img {
                width: 100%;
                max-width: 250px;
                margin: 0 auto;
            }*/
            a {
                cursor: pointer;
            }
        }
    }

    .team-photo.team-item {
        display: block;
        margin: .5em auto;
        width: 100%;
        max-width: min(100vw, 1426px);
        height: min(calc(100vw * .43), 614px);
        min-height: 300px;
        position: relative;
        background-color: #333;
        cursor: pointer;

        .button {
            font-size: .7em;
        }

        &.active {
            h2 b, h2 span {
                // display: none;
            }

            h2 {
                padding: .5em;
            }
        }
    }

    #team-list {
        display: grid;
        grid-gap: .5em;
        grid-template-columns: repeat(auto-fit, minmax(0, 350px));
        justify-content: center;
        padding-top: .5em;
    }

    .team-item {
        background-color: #333;
        height: 400px;
        position: relative;
        cursor: pointer;

        i {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            left: 0; top: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            transition: all .3s;
        }

        /*&:hover,*/
        &.active {
            i:not(.__colored) {
                filter: grayscale(1);
            }

            div.button {
                height: 75px;
            }
        }

        h2 {
            position: absolute;
            z-index: 1;
            left: 0; bottom: 0;
            padding: 2em 1em;
            color: #fff;
            margin: 0;
            font-size: 1.5em;
            background: #0008;
            text-align: center;

            span {
                width: 100%;
                font-size: .8em;
            }

                div.button {
                display: block;
                transition: all .3s;
                overflow: hidden;
                height: 0;
                // background: transparent;
                --button-hover-overlay-color: none;
                margin-top: 1em;

                b {
                    text-transform: uppercase;
                    font-size: .6em;
                }
            }
        }
    }
</style>