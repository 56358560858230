<script setup>
    import { defineProps } from 'vue';
    import './../../scss/components/button.scss';
    import './../../scss/utilities/align.scss';

    const props = defineProps({
        href: String,
        iconOnly: Boolean,
        disabled: Boolean,
        label: String,
        class: String,
        // Align
        flow: String,
        x: String,
        y: String,
        spread: String,
        reverse: Boolean,
        // Animation
        animHoverIcon: String,
        animIntensity: Number,
    });

    const styles = () => {
        return {
            '--anim-intensity': props.animIntensity,
        }
    }

    const classes = () => {
        return {
            'icon-only': props.iconOnly,
            'state:disabled': props.disabled,
            [props.class]: props.class,
            // Align
            ['align-flow-' + props.flow]: props.flow,
            ['align-x-' + props.x]: props.x,
            ['align-y-' + props.y]: props.y,
            ['align-spread-' + props.spread]: props.spread,
            'align-reverse': props.reverse,
            // Animate
            ['anim-hover-icon:'+props.animHoverIcon]: props.animHoverIcon,
        }
    }

    // TODO: When button is focus, handle spacebar/enter keypress and perform the button click action
    // TODO: aria-label added to <sub></sub> element (focusable element)

</script>

<template>
    <a class="button" :href="href" :class="classes()" :style="styles()">
        <label v-if="props.label">{{ props.label }}</label>
        <slot></slot>
        <sub tabindex="1"></sub>
    </a>
</template>