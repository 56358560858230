<template>
    <div 
    class="modal" 
    ref="modal" 
    vcen 
    v-if="isOpen">

        <div 
        class="container" 
        ref="container">

            <div class="content" ref="content" :style="{height: contentHeight}">

                <div class="header">
                    <div class="title" vcen>{{ title }}</div>
                    <div class="close" @click="close" vcen>
                        <img :src="closeIcon" v-if="closeIcon" />
                        <span v-if="!closeIcon">CLOSE</span>
                    </div>
                </div>

                <div class="slot">
                    <slot></slot>
                </div>

                <div class="footer"></div>

            </div>
            
        </div>
    </div>
</template>



<script>

	import { gsap } from "gsap";

    export default {
        name: 'modal',
        data: () => ({
            isOpen: false,
            contentHeight: '',
        }),
        props: {
            visible: {
                default: false,
                type: Boolean,
            },
            width: {
                default: '375px',
                type: String,
            },
            height: {
                default: 'auto', // auto, {value}
                type: String,
            },
            title: {
                default: '',
                type: String,
            },
            closeIcon: {
                default: '',
                type: String,
            }
        },
        watch: {
            visible(val) {
                if ( val )
                {
                    this.open()
                }
                else
                {
                    this.close()
                }
            }
        },
        methods: {
            open() 
            {
                console.log('')
                this.isOpen = true

                this.$nextTick(function()
                {
                    gsap.from(this.$refs['modal'], {
                        overwrite: true,
                        duration: .2,
                        opacity: 0,
                    })


                    gsap.to(this.$refs['container'], {
                        overwrite: true,
                        delay: .2,
                        duration: .2,
                        opacity: 1,
                        // width: 0,
                        maxWidth: this.width,
                        ease:'back.out',
                        onComplete: () => {
                            gsap.set(this.$refs['container'], {
                                // width: '100%',
                                // maxWidth: this.width,
                            })
                        }
                    })

                    if ( this.height !== 'auto' )
                    {
                        gsap.to(this.$refs['container'], {
                            delay: 0,
                            duration: 0,
                            // height:'30px',
                            maxHeight: this.height,
                            ease:'back.out',
                            onComplete: () => {
                                gsap.set(this.$refs['container'], {
                                    // height: 'auto',
                                    // maxHeight: this.height,
                                })
                            }
                        })
                    } else {
                        gsap.set(this.$refs['container'], {
                            height: this.height,
                            maxHeight: 'none',
                        })
                    }
                    

                    gsap.from(this.$refs['content'], {
                        delay: .4,
                        duration: .2,
                        opacity: 0,
                    })
                })
            },
            close()
            {
                gsap.set(this.$refs['content'], {
                    opacity: 0,
                })

                gsap.to(this.$refs['container'], {
                    overwrite: true,
                    delay: 0,
                    duration: .1,
                    width: 0,
                })

                gsap.to(this.$refs['modal'], {
                    overwrite: true,
                    delay: 0,
                    duration: .1,
                    opacity: 0,
                    onComplete: () => {
                        if ( this.isOpen )
                        {
                            this.isOpen = false
                            this.$emit('update:visible', this.isOpen)
                        }
                    }
                })
            }
        },
        mounted() {
            if ( this.height === '100%' )
            {
                this.contentHeight = '90%'
            }
        }
    }

</script>

<style lang="scss" scoped>

    $headerHeight: 48px;

    .modal {
        position:fixed;
        z-index:300000;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:#0005;
        text-align: center;
        padding:0;
        /*overflow-y:scroll;*/
        overflow-x:hidden;
    }

    .container {
        display:inline-block;
        width:100%; /* Width cannot be auto because internally, uses max-width which does not supercede width */
        height:100%;
        max-width:0;
        max-height:30px;
        background:#fff;
        overflow-y:scroll;
        overflow-x:hidden;
        text-align: left;
    }

    .container .content {
        /*width:100%;
        height:100%;*/
        /*margin-bottom:2em;*/
    }

    .container .slot {
        width:100%;
        height:100%;
        padding:1em;
        overflow-y: scroll;
    }

    .header {
        /*position:*/
        display:grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: $headerHeight;
        background: #eee;
    }

    .header div {
        padding:0 1em;
    }

    .header .title {
        text-align: left;
    }

    .header .close {
        text-align:right;
    }

    .header img {
        width:24px;
    }

    @media(max-width:450px)
    {
        .modal {
            padding:0;
        }

        .container {
            min-width:100vw;

        }
    }

</style>