<template>
    <div id="nav-bar-small" ref="bar">

        <div class="grid">

            <div class="left" vcen>

                <a href="" vcen v-if="uri[1] !== ''">
                    <img src="../assets/icons/icon-arrow-back-white.svg" />
                </a>

                <a vcen v-if="uri[1] === ''">
                    <img src="../assets/icons/icon-home-solid-white.svg" class="home" />
                </a>

                <!-- <span v-if="uri[1] !== ''">Sub Page</span> -->
            </div>

            <div class="middle" cen vcen @click="expand">
                <img src="../assets/logos/logo-flame-white.svg" ref="logo" />
            </div>

            <div class="right" right vcen>
                <a vcen @click="toggleExpansion">
                    <img src="../assets/icons/icon-menu-white.svg" v-if="!expanded" />
                    <img src="../assets/icons/icon-close-white.svg" v-if="expanded" />
                </a>
            </div>

        </div><!-- .grid -->

        <div class="expander" ref="expander">

            <div class="container">

                <div class="align-flow-x align-x-start _socials">
                    <Socials />
                </div>

                <ul v-for="(item, index) in nav" :key="index">
                    <li @click="expandSub(index)">
                        <a :href="item.sub.length ? null : item.href" target="{{ sub.target ?? '' }}" vcen>
                            <img :src="'icons/' + item.icon" />
                            <span>{{ item.label }}</span>
                        </a>
                    </li>
                    <ul v-if="item.sub.length" ref="sub[index]">
                        <li v-for="sub in item.sub" :key="sub.id">
                            <a :href="sub.href">
                                <img src="../assets/icons/icon-arrow-next-white.svg" />
                                <span>{{ sub.label }}</span>
                            </a>
                        </li>
                    </ul>
                </ul>

            </div>

        </div>

        <div class="bubble" @touchstart="toggleExpansion" @mousedown="toggleExpansion">
            <div>
                <b vcen>
                    <img src="../assets/icons/icon-drag-bar-white.svg" ref="up" />
                </b>
            </div>
        </div>

    </div>
</template>

<script>
    import { gsap } from "gsap";
    import Socials from './Socials.vue';

    export default {
        name: 'nav-bar-small',
        props: {
            uri: {
                type: Array,
                default: null,
            },
            scrollDirHide: {
                type: String,
                default: 'down'
            },
            responsiveMaxWidth: {
                type: String,
                default: '1150px'
            }
        },
        components: {
            Socials,
        },
        data: () => ({
            expanded: false,
            expanding: false,
            expandingTimer: null,
            expandedSub: null,
            nav: {
                rent: {
                    icon: 'pay.svg',
                    label: 'Pay Rent',
                    href: 'https://www.paylease.com/index_out.php?pm_id=32314802',
                    target: '_blank',
                    showInMainNav: false,
                    sub: [],
                },
                apply: {
                    icon: 'apply.svg',
                    label: 'Apply Here',
                    href: 'https://empireresidential.net/form/rental-application',
                    target: '_blank',
                    showInMainNav: false,
                    sub: [],
                },
                availabilities: {
                    icon: 'squares-four-white.svg',
                    label: 'Current Availabilities',
                    href: 'availabilities',
                    target: '_self',
                    showInMainNav: true,
                    sub: [],
                },
                portfolio: {
                    icon: 'city-solid-white.svg',
                    label: 'Portfolio',
                    showInMainNav: true,
                    sub: [
                        {
                            id: 0,
                            label: 'All',
                            href: 'portfolio/all'
                        },
                        {
                            id: 1,
                            label: 'Residential',
                            href: 'portfolio/residential'
                        },
                        {
                            id: 2,
                            label: 'Commercial',
                            href: 'portfolio/commercial'
                        },
                        /*{
                            id: 3,
                            label: 'Rent & Management',
                            href: 'portfolio/rent-and-management'
                        },*/
                        {
                            id: 4,
                            label: 'Self-Storage',
                            href: 'portfolio/self-storage'
                        },
                        {
                            id: 42,
                            label: 'Mixed Use',
                            href: 'portfolio/mixed-use'
                        },
                        {
                            id: 5,
                            label: 'Past Projects',
                            href: 'portfolio/past-projects'
                        }
                    ],
                },
                development: {
                    icon: 'icon-drafting-compass-solid-white.svg',
                    label: 'Development',
                    showInMainNav: true,
                    sub: [
                        {
                            id: -1,
                            label: 'All Projects',
                            href: 'development'
                        },
                        {
                            id: 1,
                            label: 'Current Projects',
                            href: 'development/current'
                        },
                        {
                            id: 2,
                            label: 'Upcoming Projects',
                            href: 'development/upcoming'
                        },
                        {
                            id: 3,
                            label: 'Completed Projects',
                            href: 'development/completed'
                        },
                    ],
                },
                company: {
                    icon: 'company-white.svg',
                    label: 'Our Company',
                    showInMainNav: true,
                    sub: [
                        {
                            id: 1,
                            label: 'About Us',
                            href: 'company/about-us'
                        },
                        {
                            id: 12,
                            label: 'News, Updates & Press',
                            href: 'news-and-updates'
                        },
                        {
                            id: 2,
                            label: 'Team',
                            href: 'company/team'
                        },
                        {
                            id: 3,
                            label: 'Community',
                            href: 'company/community'
                        },
                        {
                            id: 4,
                            label: 'Contact Us',
                            href: 'company/contact-us'
                        },
                    ],
                },
                /*instagram: {
                    icon: 'instagram-square-brands-white.svg',
                    label: 'Follow us on Instagram',
                    href: '#instagram',
                    showInMainNav: false,
                    sub: [],
                },*/
            },
            navSelected: [],
        }),
        watch: {
            /*navSelected: function()
            {
                
            }*/
        },
        methods: {
            expandSub()
            {
                /*if ( this.expandedSub === index )
                {
                    this.expandedSub = index;

                    gsap.to(this.$refs.sub[index], {
                        height: '',
                    });
                }
                else
                {
                    this.expandedSub = null;

                    gsap.to(this.$refs.sub[index], {
                        height: 0,
                    })
                }*/
                
            },
            showBar()
            {
                gsap.to(this.$refs.bar, {
                    y: 0,
                    duration: .5,
                    ease: "expo.out"
                });
            },
            hideBar()
            {
                if ( this.expanded ) { return false }
                this.menuEnabled = false;

                gsap.to(this.$refs.bar, {
                    y: -100,
                    duration: .5,
                    ease: "expo.out"
                });
            },
            toggleExpansion()
            {
                // Uses a timer which prevents double-actions when in chrome dev tools.

                if ( this.expanding ) { return false; }

                clearTimeout(this.expandingTimer);

                this.expandingTimer = setTimeout(() => {
                    this.expanding = false;
                }, 300)

                this.expanding = true;

                if ( !this.expanded ) 
                { 
                    this.expand();
                }
                else
                {
                    this.contract();
                }

                return false;
            },
            expand()
            {
                this.expanded = true;

                gsap.to(this.$refs.expander, {
                    height: '70vh',
                    duration: .5,
                    ease: 'expo.out'
                })

                gsap.to(this.$refs.up, {
                    opacity: 1,
                    duration: .5
                })

                gsap.to(this.$refs.logo, {
                    opacity: 0,
                    duration: .3
                })
            },
            contract()
            {
                this.expanded = false;

                gsap.to(this.$refs.expander, {
                    height: 0,
                    duration: .5,
                    ease: 'expo.out'
                })

                gsap.to(this.$refs.up, {
                    opacity: 0,
                    duration: .5
                })

                gsap.to(this.$refs.logo, {
                    opacity: 1,
                    duration: .5
                })
            }
        },
        mounted() 
        {
            /*for ( let sub in this.$refs.sub )
            {
                gsap.set(sub, {
                    height: 0,
                })
            }*/

            // Show/Hide when scrolling

            let dirPrev = '';
            let dir = '';
            let posPrev = window.pageYOffset || document.body.scrollTop;
            let posCur = posPrev;
            let distance = 0;
            let scrollTimer = null;

            window.addEventListener('scroll', () => {
                posCur = window.pageYOffset || document.body.scrollTop;
                dir = posPrev <= posCur ? 'up' : 'down';
                

                if ( dir === dirPrev )
                {
                    distance += Math.abs(posCur - posPrev);
                }
                else
                {
                    distance = 0;
                }

                posPrev = posCur;
                dirPrev = dir;

                clearTimeout(scrollTimer);

                scrollTimer = setTimeout(() => {
                    distance = 0;
                }, 100);

                if ( distance >= 50 )
                {
                    if ( dir === this.scrollDirHide )
                    {
                        this.hideBar();
                    }
                    else
                    {
                        this.showBar();
                    }
                    
                    distance = 0;
                }

                if ( posCur <= 50 )
                {
                    this.showBar();
                }
            })
        },
        created() {

        }
    }
</script>

<style lang="scss">

    @import '../styles/colors.scss';
    @import '../styles/vars.scss';
    @import '../styles/mixins.scss';

    $bubbleHeight: 40px;
    $bubbleWidth: 300px;

    #nav-bar-small {
        position: fixed;
        width: 100%;
        left: 0; top: 0;
        color: #fff;
        z-index: 2000;

        ._socials {
            width: 350px;
            padding: 1em;
            grid-gap: .25em;
            margin: 0 auto;
            border-bottom: 1px solid #fff4;
            margin-bottom: 1em;

            --button-hover-overlay-color: #0002;
            --button-hover-border-color: var(--color-primary);
            --button-icon-size: 1.1em;
            --button-border-radius: 10em;

            .button {
                justify-content: center;

                &:hover {
                    background-color: #fff2;
                }
            }
        }

        .grid {
            position: relative;
            z-index: 1;
            height: $navBarSmallHeight;
            background: unquote( $colorSecondary + 'dd' );

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0;

            & > div {
                height: 100%;

                a {
                    height: 100%;
                    padding: 0 1em;

                    &:hover {
                        background: none;
                    }

                    &:active {
                        background: #fff2;
                    }
                }
            }

            .left {

                img {
                    width: 100%;
                    max-width: 16px;

                    &.home {
                        max-width: 27px;
                    }
                }

                span {
                    color: #fff;
                    padding: 0 .5em;
                    font-size: 1.2em;
                    @include font-freight-neo-pro-book;
                }
            }

            .middle img {
                max-width: 45px;
                margin-bottom: -35px;
            }

            .right {
                img {
                    width: 100%;
                    max-width: 30px;
                }

                a {
                    border-radius: 0 0 0 1em;
                }
            }
        } /*.grid*/

        .expander {
            display: block; /* Prevents white space from occurring between expander and bubble */
            width: 100%;
            height: 0;
            background: unquote( $colorSecondary + 'dd' );
            padding: 0;
            overflow: hidden;

            .container {
                overflow-y: scroll;
                border-top: 1px solid #fff1;
                margin: 0;
                padding: 2em 0 3em 0;
                background: #000000b8;

                > ul {
                    list-style: none;
                    margin: 0;
                    width: 100%;
                    padding: 0 0 1em 0;
                    text-align: center;

                    > li {
                        width: 100%;
                        max-width: 350px;
                        display: block;
                        margin: 0 auto;
                        height: 70px;
                        text-align: left;
                        border-bottom: 1px solid #fff1;

                        a {
                            width: 100%;
                            height: 100%;
                            padding: 0 1em;
                            font-size: 1.2em;

                            &[href]:hover,
                            &[href]:active {
                                background: #fff1;
                            }

                            &:not([href]):hover,
                            &:not([href]):active {
                                background: none;
                            }
                        }

                        &:last-child {
                            border: none;
                        }

                        img {
                            max-width: 30px;
                            margin-right: 1em;
                        }

                        span {
                            color: #fff;
                        }
                    }

                    /* Sub Navs */

                    > ul {
                        list-style: none;
                        padding: 0;
                        overflow: hidden;

                        li {
                            max-width: 350px;
                            margin: 0 auto;
                            &:first-child {
                                /*margin-top: 1px;*/
                            }

                            a {
                                width: 100%;
                                height: auto;
                                padding: .75em;
                                padding-left: 1.75em;
                                /*background: #fff1;*/
                                margin-bottom: 1px;
                                text-align: left;

                                span {
                                    color: #fff;
                                    text-transform: uppercase;
                                }

                                img {
                                    max-width: 7px;
                                    margin-right: 1em;
                                }
                            }
                        }
                    }
                }
            }

            
        }
        
        .bubble {
            position: relative;
            z-index: 0;
            width: 100%;
            overflow: hidden;
            text-align: center;

            & > div {
                display: block;
                position: relative;
                height: $bubbleHeight;
            }

            & img {
                max-width: 32px;
                margin-bottom: 14px;
                vertical-align: bottom;
                opacity: 0;
            }

            b {
                position: absolute;
                bottom: 0;
                margin-left: - calc($bubbleWidth / 2);
                width: 300px;
                height: 300px;
                background: unquote( $colorSecondary + 'dd' );
                border-radius: 1000px;
            }
        }


        @media ( min-width: $navBarBreakpoint ) {
            display: none;
        }
    }

</style>
