<template>
    <div id="nav-bar-large" ref="bar">

        <div class="_inner">
            <div class="logo">
                <a href="/" vcen>
                    <img src="../assets/logos/logo-full-red-white.svg" />
                </a>
            </div>
            <ul class="nav">
    
                <li vcen @click="click('portfolio')" @mouseenter="hover('portfolio')" @mouseleave="timeout">
                    <div vcen :class="classes('portfolio')">
                        <span>Portfolio</span>
                        <img src="../assets/icons/icon-arrow-down-white.svg" />
                    </div>
                    <nav-bar-large-sub parent="portfolio" :active="isActive('portfolio')"></nav-bar-large-sub>
                </li>
    
                <li vcen @click="click('development')" @mouseenter="hover('development')" @mouseleave="timeout">
                    <div vcen :class="classes('development')">
                        <span>Development</span>
                        <img src="../assets/icons/icon-arrow-down-white.svg" />
                    </div>
                    <nav-bar-large-sub parent="development" :active="isActive('development')"></nav-bar-large-sub>
                </li>
    
                <li vcen @click="click('company')" @mouseenter="hover('company')" @mouseleave="timeout">
                    <div vcen :class="classes('company')">
                        <span>Our Company</span>
                        <img src="../assets/icons/icon-arrow-down-white.svg" />
                    </div>
                    <nav-bar-large-sub parent="company" :active="isActive('company')"></nav-bar-large-sub>
                </li>

                <li vcen @click="go('availabilities')">
                    <div vcen :class="classes('availabilities')" @mouseenter="hover('availabilities')">
                        <span>Current Availabilities</span>
                    </div>
                </li>
    
                <li class="_social align-flow-x align-y-cen">
                    <Socials />
                </li>
    
            </ul>
    
            <!-- <ul class="social align" vcen>
                <Button>
                    <Icon svg="facebook" />
                </Button>
            </ul> -->
    
            <ul class="extra">
    
                <li id="ui-rent" aria-described-by="tooltip-rent" ref="uiRent" class="pay">
                    <a href="https://www.paylease.com/index_out.php?pm_id=32314802" target="_blank" class="align-y-cen align-x-start">
                        <!-- <img src="../assets/icons/key-solid-white.svg" /> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                        </svg>
                        <span>Pay Rent</span>
                    </a>
                </li>

                <li id="ui-rent" aria-described-by="tooltip-rent" ref="uiRent" class="apply">
                    <a href="https://empireresidential.net/form/rental-application" target="_blank" class="align-y-cen align-x-start">
                        <!-- <img src="../assets/icons/key-solid-white.svg" /> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd" />
                        </svg>
                        <span>Apply Here</span>
                    </a>
                </li>
    
                <!-- <li id="ui-instagram" aria-described-by="tooltip-instagram" ref="uiInstagram" @mouseenter="tipShow($refs.tooltipInstagram)" @mouseleave="tipHide($refs.tooltipInstagram)">
                    <a href="#">
                        <img src="../assets/icons/instagram-square-brands-white.svg" />
                    </a>
                </li> -->
    
    
            </ul>
    
            <!-- <span id="tooltip-rent" role="tooltip" ref="tooltipRent">
                Pay Rent
                <div id="arrow" data-popper-arrow></div>
            </span> -->
    
            <!-- <span id="tooltip-instagram" role="tooltip" ref="tooltipInstagram">
                Follow us on Instagram!
                <div id="arrow" data-popper-arrow></div>
            </span> -->
        </div><!-- ._inner -->

    
    </div>
</template>

<script>
    import NavBarLargeSub from './NavBarLargeSub.vue';
    import Socials from './Socials.vue';
    // import { createPopper } from '@popperjs/core';
    import { gsap } from "gsap";

    export default {
        name: 'nav-bar-large',
        props: {
            uri: Array,
            scrollDirHide: {
                type: String,
                default: 'down'
            },
            responsiveMaxWidth: {
                type: String,
                default: '1150px'
            }
        },
        data: () => {
            return {
                menuEnabled: false,
                activeNav: '',
                timer: Object,
                scroll: {},
            }
        },
        components: {
            NavBarLargeSub,
            Socials,
        },
        methods: {
            click (str) {
                // this.menuEnabled = !this.menuEnabled
                this.menuEnabled = true;
                this.activeNav = str
            },
            go (str) {
                window.location.href = str;
            },
            hover (str) {
                clearTimeout(this.timer);
                this.activeNav = str
            },
            classes (str) {
                return {
                    active: this.activeNav === str,
                    current: this.uri[1] === str
                }
            },
            timeout () {
                clearTimeout(this.timer);

                this.timer = setTimeout( () => {
                    this.menuEnabled = false;
                    this.activeNav = '';
                }, 300 )
            },
            isActive (str) {
                return this.activeNav === str && this.menuEnabled
            },
            tipShow (tip) {
                tip.setAttribute('data-show', '');
            },
            tipHide (tip) {
                tip.removeAttribute('data-show');
            },
            showBar()
            {
                gsap.to(this.$refs.bar, {
                    y: 0,
                    duration: .5,
                    ease: "expo.out"
                });
            },
            hideBar()
            {
                this.menuEnabled = false;

                gsap.to(this.$refs.bar, {
                    y: -100,
                    duration: .5,
                    ease: "expo.out"
                });
            }
        },
        mounted: function() {

            // createPopper(this.$refs.uiRent, this.$refs.tooltipRent, {
            //     placement: 'bottom',
            //     modifiers: [
            //         {
            //             name: 'offset',
            //             options: {
            //                 offset: [0, 8],
            //             },
            //         },
            //     ],
            // });

            // createPopper(this.$refs.uiInstagram, this.$refs.tooltipInstagram, {
            //     placement: 'bottom',
            //     modifiers: [
            //         {
            //             name: 'offset',
            //             options: {
            //                 offset: [0, 8],
            //             },
            //         },
            //     ],
            // });

            // Show/Hide when scrolling

            // let dirPrev = '';
            // let dir = '';
            // let posPrev = window.pageYOffset || document.body.scrollTop;
            // let posCur = posPrev;
            // let distance = 0;
            // let scrollTimer = null;

            // window.addEventListener('scroll', () => {
            //     posCur = window.pageYOffset || document.body.scrollTop;
            //     dir = posPrev <= posCur ? 'up' : 'down';
                

            //     if ( dir === dirPrev )
            //     {
            //         distance += Math.abs(posCur - posPrev);
            //     }
            //     else
            //     {
            //         distance = 0;
            //     }

            //     posPrev = posCur;
            //     dirPrev = dir;

            //     clearTimeout(scrollTimer);

            //     scrollTimer = setTimeout(() => {
            //         distance = 0;
            //     }, 100);

            //     if ( distance >= 50 )
            //     {
            //         if ( dir === this.scrollDirHide )
            //         {
            //             this.hideBar();
            //         }
            //         else
            //         {
            //             this.showBar();
            //         }
                    
            //         distance = 0;
            //     }

            //     if ( posCur <= 50 )
            //     {
            //         this.showBar();
            //     }
            // })
        },
    }
</script>

<style lang="scss">

    @import '../styles/colors.scss';
    @import '../styles/vars.scss';
    @import '../styles/mixins.scss';

    #nav-bar-large {

        
        // padding: 0 0 0 1em;
        height: $navBarLargeHeight;
        background: unquote( $colorSecondary + 'dd' );
        position: fixed;
        left: 0; top: 0;
        z-index: 2000;
        
        ._inner {
            max-width: calc(var(--max-content-width) * 1.3);
            margin: 0 auto;
            height: 100%;

            display: grid;
            grid-template-columns: max-content auto max-content;
            grid-gap: 1em;
        }

        .logo {
            text-align: left;

            a {
                display: block;
                width: 100%;
                height: 100%;
                padding: 0 1em;

                &:hover {
                    background: inherit;
                }

                img {
                    width: 100%;
                    max-width: 240px;
                }
            }
        }

        ul.nav {
            margin: 0;
            list-style: none;
            display: grid;
            grid-template-columns: auto auto auto auto 1fr;
            // max-width: 900px;

            li {
                position: relative;
                text-align: center;

                &._social {
                    width: 100%;
                    justify-content: end;
                    padding: 0 1em;
                    grid-gap: .25em;

                    --button-hover-overlay-color: #0002;
                    --button-hover-border-color: var(--color-primary);
                    --button-icon-size: 1.1em;
                    --button-border-radius: 10em;

                    .button {
                        justify-content: center;

                        &:hover {
                            background-color: #fff2;
                        }
                    }
                }

                div {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    padding: 0 3em;
                    transition: all .3s;

                    span {
                        color: #fff;
                        /*text-transform: uppercase;*/
                        font-size: 1.1em;
                    }

                    &:hover,
                    &.active {
                        background: #fff1;
                    }

                    &.current {
                        background: #fff2;
                        /*background: unquote($colorPrimary + '99');*/
                    }

                    /*&.active {
                        background: $colorPrimary;

                        span {
                            color: #fff;
                        }
                    }*/

                    img {
                        height: 9px;
                        margin: .3em 0 0 .5em;
                    }
                }
            }
        }

        ul.extra {
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: right;

            display: grid;
            grid-template-areas: 
                'pay'
                'apply'
            ;
            gap: 1px;

            li {

                &.pay {
                    grid-area: pay;
                }

                &.apply {
                    grid-area: apply;
                    background: #fff1;
                }

                display: block;
                // margin-left: .5em;
                transition: all .3s;
                border-radius: .3em;
                text-align: center;
                background: var(--color-primary);
                height: 100%;
                width: 100%;
                border-radius: 0;
                color: #fff;

                &:hover {
                    background: #fff2;
                }

                a {
                    padding: 0 1em;
                    border-radius: 0;
                    width: 100%;
                    height: 100%;

                    &:hover {
                        background: none;
                    }
                }

                span {
                    @include font-freight-neo-pro-black;
                    color: #fff;
                    // text-transform: uppercase;
                    display: block;
                    padding: .2em 0;
                    font-size: 1.1em;
                }
            }

            img, svg {
                width: 100%;
                max-width: 25px;
                color: #fff;
            }
        }

        #tooltip-rent,
        #tooltip-instagram {
            visibility: hidden;
            width: auto;
            background: $colorPrimary;
            color: white;
            padding: 4px 8px;
            font-size: 13px;
            border-radius: 4px;
            /*text-transform: uppercase;*/
            font-size: 1em;
            @include font-freight-neo-pro-book;

            &[data-show] {
                visibility: visible;
            }

            &[data-popper-placement^='top'] > #arrow {
              bottom: -4px;
            }

            &[data-popper-placement^='bottom'] > #arrow {
              top: -4px;
            }

            &[data-popper-placement^='left'] > #arrow {
              right: -4px;
            }

            &[data-popper-placement^='right'] > #arrow {
              left: -4px;
            }
        }

        #arrow,
        #arrow::before {
          position: absolute;
          width: 8px;
          height: 8px;
          z-index: -1;
        }

        #arrow::before {
          content: '';
          transform: rotate(45deg);
          background: $colorPrimary;
        }

        @media ( max-width: 1500px ) {
            .logo {
                a img {
                    max-width: 180px;
                }
            }

            ul.nav li {
                > div {
                    span, a span {
                        font-size: 14px;
                    }

                    padding: 0 30px;
                }
            }
        }

        @media ( max-width: $navBarBreakpoint ) {
            display: none;
        }
    }

</style>
