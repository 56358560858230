<template>
    <div id="contact-us-email">

        <p>
            <a @click="showModal = true" btn class="large">{{ buttonLabel || 'Email Us' }}</a>
        </p>

        <Modal v-model:visible="showModal" width="500px" title="Contact Empire Residential">
            <ContactForm :project-id="projectId" :contact-id="contactId" contact-type="property"></ContactForm>
        </Modal>

    </div>
</template>

<script>
    import Modal from './Modal.vue';
    import ContactForm from './ContactForm.vue';

    export default {
        name: 'contact-us-email',
        components: {
            Modal,
            ContactForm,
        },
        props: {
            buttonLabel: String,
            contactId: Number,
            projectId: Number,
        },
        data: () => ({
            showModal: false,
        }),
        methods: {
            
        },
    }
</script>

<style lang="scss">

</style>