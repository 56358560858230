<template>
    <ul id="nav-bar-large-sub" ref="subMenu">
        <li v-for="item in navSelected" :key="item.id" :class="'nav-item-'+item.id" ref="subItems">
            <!-- <div></div> -->
            <a :href="item.href">
                <span>{{ item.label }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
    import { gsap } from "gsap";
    import { CSSPlugin } from "gsap/CSSPlugin";
    gsap.registerPlugin(CSSPlugin);

    export default {
        name: 'nav-bar-large-sub',
        props: {
            parent: {
                type: String,
                default: null,
            },
            active: Boolean,
        },
        data: () => ({
            nav: {
                portfolio: [
                    {
                        id: 0,
                        label: 'All',
                        href: 'portfolio/all'
                    },
                    {
                        id: 1,
                        label: 'Residential',
                        href: 'portfolio/residential'
                    },
                    {
                        id: 2,
                        label: 'Commercial',
                        href: 'portfolio/commercial'
                    },
                    /*{
                        id: 3,
                        label: 'Rent & Management',
                        href: 'portfolio/rent-and-management'
                    },*/
                    {
                        id: 4,
                        label: 'Self-Storage',
                        href: 'portfolio/self-storage'
                    },
                    {
                        id: 42,
                        label: 'Mixed Use',
                        href: 'portfolio/mixed-use'
                    },
                    {
                        id: 5,
                        label: 'Past Projects',
                        href: 'portfolio/past-projects'
                    }
                ],
                development: [
                    {
                        id: -1,
                        label: 'All Projects',
                        href: 'development'
                    },
                    {
                        id: 1,
                        label: 'Current Projects',
                        href: 'development/current'
                    },
                    {
                        id: 2,
                        label: 'Upcoming Projects',
                        href: 'development/upcoming'
                    },
                    {
                        id: 3,
                        label: 'Completed Projects',
                        href: 'development/completed'
                    },
                ],
                company: [
                    {
                        id: 1,
                        label: 'About Us',
                        href: 'company/about-us'
                    },
                    {
                        id: 12,
                        label: 'News, Updates & Press',
                        href: 'news-and-updates'
                    },
                    {
                        id: 2,
                        label: 'Team',
                        href: 'company/team'
                    },
                    {
                        id: 3,
                        label: 'Community',
                        href: 'company/community'
                    },
                    {
                        id: 4,
                        label: 'Contact Us',
                        href: 'company/contact-us'
                    },
                ],
            },
            navSelected: [],
        }),
        watch: {
            active: function(val) {
                if ( val )
                {
                    // animate in

                    gsap.killTweensOf(this.$refs.subMenu);

                    gsap.set(this.$refs.subMenu, {
                        display: 'block',
                    })

                    gsap.to(this.$refs.subMenu, {
                        opacity: 1,
                        marginTop: '0px',
                        borderRadius: '0',
                        duration: .3,
                    })
                    
                }
                else
                {
                    // animate out

                    gsap.killTweensOf(this.$refs.subMenu);

                    gsap.set(this.$refs.subMenu, {
                        opacity: 0,
                        marginTop: '30px',
                        borderRadius: '2em',
                        duration: .3,
                        onComplete: () => {
                            gsap.set(this.$refs.subMenu, {
                                display: 'none',
                            })
                        }
                    })
                }
            }
        },
        mounted() {
            this.navSelected = this.nav[this.parent];

            this.$nextTick(() => {
                gsap.set(this.$refs.subMenu, {
                    marginTop: '30px',
                    borderRadius: '2em',
                    opacity: 0,
                    display: 'none',
                })
            })
            
        }
    }
</script>

<style lang="scss">

    @import '../styles/colors.scss';
    @import '../styles/vars.scss';
    @import '../styles/mixins.scss';

    #nav-bar-large-sub {
        position: absolute;
        left: 0;
        top: $navBarLargeHeight;
        list-style: none;
        padding: 0;
        /*background: unquote( $colorPrimary + 'ee' );*/
        background: #000e;
        overflow: hidden;

        li {
            position: relative;

            &:hover {
                /*div {
                    width: 100% !important;
                }*/

                a {
                    /*background: unquote( $colorPrimary + 'ee' );*/
                    background: #fff5;

                    span {
                        /*color: $colorPrimary;*/
                    }
                }

            }

            a {
                width: 100%;
                min-width: 300px;
                height: 100%;
                padding: 1em 1.5em;
                text-align: left;
                transition: background .3s;

                

                span {
                    // text-transform: uppercase;
                    color: #fff;
                    @include font-freight-neo-pro-book;
                    white-space: nowrap;
                    transition: color .3s;
                    font-size: 1.2em;
                }
            }

            /*div {
                position: absolute;
                left: 0; top: 0;
                width: 0% !important;
                height: 100%;
                background: #fff;
                z-index: 0;
                transition: width .4s !important;
            }*/
        }
    }

</style>
