<template>
    <div id="home-banner">
        <swiper
        :space-between="30"
        :pagination="{ clickable: true }"
        :autoplay="{ delay: 5000 }"
        :loop="true"
        :speed="600"
        @swiper="onSwiper">

            <swiper-slide v-for="(slide, index) in slides" :key="index">

                <!-- <div :style="'background-image: url(' + slide.imageComputed + ')'"></div> -->
                <div></div>

                <div :style="'background-image: url(' + slide.imageComputed + ')'">
                    <div vcen>
                        <span v-if="index === 0">
                            <img src="../assets/logos/logo-full-white.svg" /><br />
                            <h2>{{ slide.text }}</h2>
                        </span>
                        <h1 v-if="index !== 0">{{ slide.text }}</h1>
                    </div>
                </div>

                <!-- <div :style="'background-image: url(' + slide.imageComputed + ')'"></div> -->
                <div></div>


            </swiper-slide>
            
        </swiper>
    </div>
</template>

<script>
    import SwiperCore, {Autoplay, Pagination} from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper.scss';
    // import 'swiper/components/pagination/pagination.scss'; // This does not load when built for production

    SwiperCore.use([Pagination, Autoplay]);

    export default {
        name: 'nav-bar-large',
        props: {
            slides: Object,
        },
        data: () => {
            return {
                
            }
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        methods: {
            onSwiper(swiper)
            {
                // Must call "update" with a delay in order for swiper to initialize on page load.
                // Otherwise, does not initialize until window is resized.

                setTimeout(() => {
                    swiper.update();
                }, 100)
                
            },
            onSlideChange()
            {
                // console.log('slide change');
            }
        },
        mounted: function() 
        {
            // Swiper.update();
        },
    }
</script>

<style lang="scss">

    @import '../styles/colors.scss';
    @import '../styles/vars.scss';
    @import '../styles/mixins.scss';

    #home-banner {
        .swiper-container {
            width: 100%;
            height: 80vh;
            min-height: 30vw;
            max-height: 625px;
            background: rgba(0,0,0,.07);


            .swiper-pagination {
                bottom: 25px;

                span {
                    width: 25px;
                    height: 25px;
                    border: 1px solid #fff;
                    background: #fff;
                    opacity: 1;
                    margin: 0 .75em;

                    &.swiper-pagination-bullet-active {
                        background: $colorPrimary;
                    }
                }
            }

            .swiper-wrapper {

                .swiper-slide {

                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;

                    > div:nth-child(2) {
                        width: 100vw;
                        max-width: calc(var(--max-content-width) * 1.3);
                    }

                    > div:nth-child(1), > div:nth-child(3) {
                        // filter: grayscale(1) contrast(.1);
                        // -webkit-filter: contrast(.5);
                    }

                    & > div {
                        height: 100%;
                        text-align: center;

                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;

                        & > div {
                            height: 100%;
                            background-color: rgba(0,0,0,.17);
                            padding: 1em;

                            img {
                                width: 100%;
                                max-width: 400px;
                            }

                            @media ( max-width: $navBarBreakpoint )
                            {
                                background-color: rgba(0,0,0,.4);
                            }
                        }

                        h1,
                        h2 {
                            /*@include font-freight-neo-pro-black;*/
                            @include font-display-bold;
                            color: #fff;
                            font-size: 3em;
                            margin-top: $navBarLargeHeight * .7;
                            text-shadow: 0 0 15px #000000;
                            max-width: 650px;
                        }

                        h2 {
                            font-size: 3em;
                        }

                        @media ( max-width: 600px ) {
                            h1,
                            h2 {
                                font-size: 2em;
                            }
                        }

                        &:first-child {

                            img {
                                display: none;
                            }



                            @media ( max-width: $navBarBreakpoint )
                            {
                                h2 {
                                    /*@include font-display;*/
                                    margin-top: 10px;
                                    font-size: 1.3em;
                                }
                                img {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Custom Swiper Pagination CSS since the one from the package doesnt load in production build?! */

        .swiper-container {
            .swiper-pagination {
                position: absolute;
                z-index: 10;
                text-align: center;

                .swiper-pagination-bullet {
                    border-radius: 100%;
                }
            }
        }
    }

</style>
