<script setup>
    import { defineProps, ref, onMounted, reactive } from 'vue';
    import { icons } from './../services/icons.js';
    import './../scss/components/icon.scss';

    const icon = ref(null);

    let state = reactive({
        src: null,
    })

    const props = defineProps({
        svg: String,
        img: String,
        rotate: String,
        anim: String,
    });

    const styles = () => {
        let ret = {};

        if ( props.rotate ) {
            ret['--rotate'] = props.rotate + 'deg';
        }

        return ret;
    }

    const classes = () => {
        return {
            ['anim:'+props.anim]: props.anim,
        }
    }

    onMounted (() => {
        if ( props.svg ) {
            icon.value.innerHTML = props.svg.search('<svg') !== -1 ? props.svg : icons.svg[props.svg];
        } else if ( props.img ) {
            state.src = props.img.search('http') !== -1 ? props.img : icons.img[props.img]; // TODO:  || props.img.search('base64') !== -1
        }
    })

</script>

<template>
    <i class="icon" ref="icon" :style="styles()" :class="classes()">
        <img :src="state.src" v-if="props.img" />
        <slot></slot>
    </i>
</template>