<template>
    <div id="progress" cen vcen v-if="visible">
        <ProgressSpinner />
    </div>
</template>

<script>
    import ProgressSpinner from 'primevue/progressspinner';

    export default {
        name: 'contact-us-email',
        components: {
            ProgressSpinner,
        },
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss">
    #progress {
        position: fixed;
        z-index: 2000;
        left: 0; top: 0;
        background: #fff9;
        width: 100%;
        height: 100vh;
    }
</style>