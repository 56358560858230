import { createApp } from 'vue'
import App from './App.vue'
import './main.scss'
import { gsap } from "gsap";
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
// import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
gsap.registerPlugin({});

// createApp.config.devtools = process.env.NODE_ENV === 'development';

createApp(App).use(PrimeVue, {ripple: true}).use(ToastService).mount('#app');

/* PrimeVue */

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';

// app.use(PrimeVue);

/* ICONS */

// import IconArrowUp from './assets/arrow-up.svg';
// Vue.component('arrow-up', IconArrowUp);

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}